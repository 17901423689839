import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'
import DynamicZone from '../components/DynamicZone/DynamicZone'

const aboutTemplate = ( { data: { strapi: { aboutPage } }, location } ) => {

	return (
		<>
			<SEO
				title={ aboutPage.aboutTitle }
				description={ aboutPage.seoDescription }
				pathname={ location.pathname } />

			{ aboutPage && aboutPage.content &&
				<DynamicZone components={ aboutPage.content } />
			}
		</>
	)
}

export default aboutTemplate

export const query = graphql`
	query aboutUsSubPageQuery($id: ID!) {
		strapi {
			aboutPage(id: $id) {
				aboutSlug
				aboutTitle
				seoDescription
				content {
					... on StrapiQuery_ComponentFounderFounderIllustration {
						id
						founderIllustrationAlignment
						founderIllustrationImage
						founderIllustrationInfo
						founderIllustrationTitle
					}
					... on StrapiQuery_ComponentFounderFounderImage {
						id
						founderImageAlignment
						founderImageInfo
						founderImageTitle
						founderImage {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750, maxHeight: 750) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentGeneralHeroBanner {
						id
						heroDescription
						heroTitle
						imageFocus
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000, maxHeight: 500) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentAboutQuoteBanner {
						id
						quoteText
					}
					... on StrapiQuery_ComponentAboutAboutHero {
						id
						aboutHeroInfo
						aboutHeroStrapline
						aboutHeroTitle
					}
					... on StrapiQuery_ComponentAboutServicesBanner {
						id
						serviceInfo
						serviceLinkText
						serviceLinkUrl
						serviceType
					}
				}
			}
		}
	}
`